<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-s-c" style="padding-left:0.3rem">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name flex-c-c-s" style="margin-left:0.3rem">
          <div class="invoice_status "> {{ ['已关闭','待开票','已完成'][info.status] }} </div>
          <div class="invoice_money flex-r-s-c">
            <div class="invoice_money_title">商品实付：￥<span style="font-size: 0.43rem;">{{ info.orderPrice }}</span></div>
            <div class="invoice_money_title" style="margin-left:0.43rem">运费金额：￥<span
              style="font-size: 0.43rem;"
            >{{ info.expressPrice }}</span></div>
          </div>
        </div>
        <div class="top_icon" />
      </div>
    </div>

    <div class="popup_info">
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">发票类型</div>
        <div class="popup_info_item_msg">{{ ['','增值税电子普通发票','增值税电子专用发票'][info.invoicesType] }}</div>
      </div>
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">开票方式</div>
        <div class="popup_info_item_msg">{{ ['','按商品明细','按商品类别'][info.invoicesMethod] }}</div>
      </div>
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">公司名称</div>
        <div class="popup_info_item_msg">{{ info.invoicesCompanyName }}</div>
      </div>
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">公司税号</div>
        <div class="popup_info_item_msg">{{ info.invoicesTaxNum }}</div>
      </div>
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">银行账户</div>
        <div class="popup_info_item_msg">{{ info.bankAccount || '-' }}</div>
      </div>
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">开户银行</div>
        <div class="popup_info_item_msg">{{ info.bankName || '-' }}</div>
      </div>
      <div class="popup_info_item flex-r-s-s">
        <div class="popup_info_item_name">注册地址</div>
        <div class="popup_info_item_msg" style="max-width: 7rem">{{ info.registeredAddress || '-' }}</div>
      </div>
      <div class="popup_info_item flex-r-s-c">
        <div class="popup_info_item_name">公司电话</div>
        <div class="popup_info_item_msg">{{ info.officeNumber || '-' }}</div>
      </div>

      <div v-if="info.status === 2" class="invoice_imgList flex-r-s-c">
        <div v-for="(item,index) in info.picUrlList" :key="index" style="position: relative;width:4rem;margin-left:0.5rem;margin-bottom:0.37rem"
             @click="viewUrl(item)"
        >
          <van-image width="4rem" height="2.55rem" :src="item" />
          <div class="invoice_imgList_bottom">点击预览发票</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import { invoicesDetail } from '@/api/order'
import { Base64 } from 'js-base64'
export default {
  name: 'DetailInvoice',
  data() {
    return {
      outOrder: '',
      info: {}
    }
  },
  created() {
    this.outOrder = this.$route.query.outOrder
    this.getDetail()
  },
  methods: {
    backPath() { window.history.back() },
    viewUrl(url) {
      var fileUrl = encodeURI(Base64.encode(url))
      window.open('http://file.xiaoyi120.com/onlinePreview?url=' + fileUrl)
    },
    getDetail() { invoicesDetail(this.outOrder).then(res => { this.info = res.data }) }

  }
}
</script>

<style lang="scss">
.van-popup {
  border-radius: 0.27rem;
}
</style>

